@import '../../styles/colors';
@import '../../styles/dimensions';
@import '../../styles/screens';

.section {
    background-color: var(--step-dark-gray);
}

.logo {
    height: $press-logo-height;
}

.content {
    padding: $gap-medium $gap-small;
    width: 100%;
}

@include screen-width($min: 'medium', $max: 'large-tablet') {
    .content {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
}

@include screen-max-width('large-tablet') {
    .link {
        margin: 0 $gap-medium $gap-medium 0;
    }

    .link:last-child {
        margin-right: 0;
    }

    .logos,
    .content {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
}

@supports (display: grid) {
    @include screen-min-width('large-tablet') {
        .logos {
            display: flex;
            gap: $gap-medium;
            justify-content: flex-start;
        }

        .content {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            gap: $gap-small;
            justify-content: center;
        }
    }

    @include screen-min-width('large-tablet') {
        .link {
            margin-bottom: 0;
        }
    }
}

@media print {
    .section {
        break-inside: avoid;
        display: none;
    }
}
