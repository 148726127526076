@import '../../styles/screens';
@import '../../styles/colors';
@import '../../styles/dimensions';

.section {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.rating {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-right: $gap-small;
}

.reviews {
    color: var(--step-white);
}
